export const contentText = [
  {
    type: 'textarea',
    label: 'Pharmacist: Please Fill text box below',
    name: 'text',
    rows: '6',
  },
]

export const letterTemplate = [{
  component: 'h3',
  children: 'Patient Information',
  class: 'tw-text-2xl tw-mb-4',
},
{
  component: 'div',
  class: 'tw-grid tw-grid-cols-6 tw-gap-6',
  children: [
    {
      type: 'text',
      label: 'Doctor Name',
      name: 'drname',
      key: 'drname',
    },
    {
      type: 'text',
      label: 'cpso',
      name: 'cpso',
      key: 'cpso',
    },
    {
      type: 'text',
      label: 'Dr Fax',
      name: 'drfax',
      key: 'drfax',
    },
    {
      type: 'text',
      label: 'Patient Name',
      name: 'patientName',
      key: 'patientName',
    },
    {
      type: 'text',
      label: 'Patient Date of Birth',
      name: 'patientdateofbirth',
      key: 'patientdateofbirth',
    },
    {
      type: 'text',
      label: 'Pharmacist Name',
      name: 'rphName',
      key: 'rphName',
    },
  ],
},
]
